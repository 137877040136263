<template>
  <section class="presentation__agent animate__animated">
    <div class="presentation__agent-conteiner" v-if="!AgentData.agent">
      <q-skeleton type="circle" size="144px" />
      <q-skeleton type="text" height="30px" width="150px" />
      <q-skeleton type="text" height="30px" width="130px" />
      <q-skeleton type="text" height="30px" width="120px" />
      <q-skeleton type="text" height="30px" width="80px" />
    </div>
    <div class="presentation__agent-conteiner" v-else>
      <div class="avatar-conteiner">
        <q-avatar size="112px">
          <q-inner-loading
            v-if="!AgentData.agent"
            style="z-index: 999"
            :showing="PStore.$state.agentInfoLoader"
          >
            <custom-loader />
          </q-inner-loading>
          <img
            v-else
            :src="AgentData.agent.avatar_url"
            alt="avatar"
            onerror="this.src='https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"
          />
        </q-avatar>
        <button class="info-menu">
          <base-icon name="ellipsis-horizontal" color="#7B798F" />
          <q-menu
            :offset="[70, 0]"
            auto-close
            anchor="bottom left"
            self="top middle"
            class="info-menu-dropdown"
          >
            <q-item clickable @click="copyToClipboard(false)">
              <q-item-section>
                <button>
                  <base-icon name="copy" size="20px" color="var(--violet-400)" />
                  Скопировать ссылку
                </button>
              </q-item-section>
            </q-item>
            <q-item clickable @click="savePdf">
              <q-item-section>
                <button>
                  <base-icon name="download" size="20px" color="var(--violet-400)" />
                  Сохранить в PDF
                </button>
              </q-item-section>
            </q-item>
          </q-menu>
        </button>
      </div>
      <div class="agent-info">
        <h4 class="agent-info-name">
          {{ AgentData.agent.last_name + ' ' + AgentData.agent.first_name }}
        </h4>
        <p class="agent-info-work">{{ switchWorkAs(AgentData.agent.employment_status) }}</p>
        <span class="agent-info-stars">
          <q-icon size="16px" style="color: #fb923c" name="star" />
          <p>4,9 (40 отзывов)</p>
        </span>
        <div class="abot-expander" :class="{ expanded: isAboutExpanded }">
          <button @click="expandeAbout">
            {{ isAboutExpanded ? 'Скрыть' : 'Подробнее' }}
            <base-icon name="triangle-down" color="#7444AB" size="12px"></base-icon>
          </button>
          <div class="agent-about">
            <p class="agent-about-title">О себе:</p>
            <p class="agent-about-about">
              {{ AgentData.agent.about_myself }}
            </p>
          </div>
          <div class="agent-chips">
            <q-badge
              outline
              rounded
              class="agent-chips-chip"
              v-for="chip in AgentData.agent.directions"
              :label="switchDirections(chip)"
            />
          </div>

          <span class="agent-info-city">
            <base-icon name="pin" size="16px" color="var(--slate-400)" />
            <p>{{ AgentData.agent.city }}</p>
          </span>
        </div>
      </div>
      <div class="agent-butts">
        <button
          class="review-butt"
          @click="PopUp.callMobilPopUp({ value: 'AgentContactsPopUp', position: 'bottom' })"
        >
          Контакты
        </button>
        <button class="review-butt pale-back" @click="clickReview">
          {{
            $props.isOwner?.presentation && !$props.isOwner?.presentation.can_edit
              ? 'Оставить отзыв'
              : 'Настройки'
          }}
        </button>
      </div>
      <div class="presentation__header-title">
        <h3>
          {{
            `Подобранные варианты (${
              PresentationData.objects ? PresentationData.objects.length : 0
            })`
          }}
        </h3>
        <!-- <p>Вторичка · до 20 млн</p> -->
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { PopUpStore } from '~/store/popUp'
import { PresentationStore } from '~/store/PresentationStore'
import { switchWorkAs } from '~/helpers/agentWorkAsSwitch'
import { PresentationAll } from '~/interfaces/tsPresentation'

const props = defineProps({
  isOwner: {
    required: false,
    type: Object as () => PresentationAll,
  },
})

const router = useRouter()

const PStore = PresentationStore()
const PopUp = PopUpStore()
const PresentationData = computed(() => PStore.$state.tableObjsData)
const isAboutExpanded = ref(false)

const expandeAbout = () => {
  isAboutExpanded.value = !isAboutExpanded.value
}

const savePdf = () => {
  PopUp.callPopUp({ value: 'PdfPopUp', data: { opts: { name: 'allObjs' } }, persistent: true })
}

const copyToClipboard = async (c) => {
  try {
    await navigator.clipboard.writeText(window.location.href)
  } catch (error) {
    console.error('Failed to copy to clipboard', error)
  }
}

const AgentData = computed(() => PStore.$state.tableObjsData)

const switchDirections = (
  type:
    | 'resale'
    | 'new_building'
    | 'country'
    | 'interregional'
    | 'investments'
    | 'mortgage'
    | 'refinancing'
    | 'legal_assistance',
) => {
  let value: string
  const map: Record<typeof type, string> = {
    resale: 'Вторичка',
    new_building: 'Новостройки',
    country: 'Загородная',
    interregional: 'Межрегиональная',
    investments: 'Инвестиции',
    mortgage: 'Ипотека',
    refinancing: 'Рефинансирование',
    legal_assistance: 'Юр. сопровождение',
  }
  value = map[type] ?? ''
  return value
}

const clickReview = () => {
  if (props.isOwner?.presentation && !props.isOwner?.presentation.can_edit) {
    PopUp.callPopUp({ value: 'LeaveReview' })
  } else {
    router.push('/profile')
  }
}
</script>

<style scoped lang="scss">
.presentation {
  &__header-title {
    display: block;
    h3 {
      color: var(--slate-800);
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
    }
  }
  &__agent {
    width: 100%;
    height: fit-content;
    background: var(--white, #fff);
    box-shadow: 0px 2px 8px 0px rgba(29, 29, 31, 0.1);
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
    padding-top: 16px;
    z-index: 100;
    top: 48px;
    left: 48px;
    &-sticky {
      width: 100vw;
      height: 52px;
      position: fixed;
      top: 0;
      background-color: red;
    }
    &-conteiner {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: start;
      align-items: center;
      gap: 16px;
      color: var(--slate-500);

      .avatar-conteiner {
        display: flex;
        width: 100%;
        height: 45px;
        justify-content: space-between;
        .info-menu {
          display: flex;
          width: 40px;
          height: 40px;
          padding: 8px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 90px;
          border: 1px solid var(--slate-200);
          background: transparent;
        }
        .q-avatar {
          top: -69px;
        }
      }
      .agent {
        &-info {
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-items: start;
          cursor: default;
          .abot-expander {
            display: flex;
            overflow: hidden;
            transition: max-height 0.8s ease-in-out;
            max-height: 25px;
            flex-direction: column-reverse;
            align-items: flex-start;
            button {
              display: flex;
              align-items: center;
              color: var(--violet-400);
              font-family: Golos;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              border: none;
              background: transparent;
              cursor: pointer;
              padding: 0;
            }
          }

          .abot-expander.expanded {
            max-height: 450px;
            max-width: 100%;
          }
          &-name {
            color: var(--slate-800);
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            margin-top: 0;
          }
          &-work {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin: 0;
          }
          &-stars {
            display: flex;
            align-items: center;
            gap: 4px;
          }
          &-city {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
        &-chips {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;
          align-items: center;
          justify-content: flex-start;
          margin-top: 8px;
          &-chip {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 4px;
            padding-bottom: 4px;
            color: var(--slate-500);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            border-radius: 90px;
            border: 1px solid var(--slate-200);
            cursor: default;
          }
        }
        &-about {
          display: grid;
          align-items: center;
          justify-items: flex-start;
          justify-self: flex-start;
          font-size: 14px;
          font-style: normal;
          line-height: 22px;
          margin-top: 8px;
          max-width: 100%;
          p {
            margin: 0;
            overflow-wrap: anywhere;
          }
          &-title {
            color: var(--slate-800);
            font-weight: 500;
          }
          &-about {
            color: var(--slate-500);
            font-weight: 400;
          }
        }
        &-butts {
          display: flex;
          gap: 8px;
          width: 100%;
          .review-butt {
            display: flex;
            padding: 12px 12px;
            width: fit-content;
            white-space: nowrap;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            border-radius: 12px;
            background: var(--violet-400);
            color: var(--white, #fff);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            border: none;
            margin: 0;
          }
          .pale-back {
            background: var(--slate-100);
            color: #7444AB;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .presentation {
    &__agent {
      &-conteiner {
        .agent-butts {
          width: 100%;
        }
        .avatar-conteiner {
          .info-menu {
            display: none;
          }
        }
      }
    }
  }
}
</style>
